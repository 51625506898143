@import '../../utils/variables.scss';

.form{
    max-width: 500px;
    margin:60px auto;
    padding: 20px 0px;
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size: 1.5rem;
    font-weight: 600;
    border: 2px solid $border-color;
    color: $text-color;
    box-shadow: 0px 0px 15px black;
    box-sizing: border-box;
}

.container{
    margin: 30px 10px;
    display: flex;
    align-items: center;
    label{
        margin-right: 10px;
    }
    input,textarea{
        width: 15rem;
        padding: 5px;
        font-size: 1rem;
        &:focus{
            border:1px solid green;
        }
    }
}

.containerImg{
    margin: 30px 10px;
    p{
        font-size: 0.8rem;
        color: red;
    }
}

.description{
    width:15rem ;
}


.submit{
    display: block;
    margin: 20px auto;
    padding: 4px 20px;
    font-size: 1.5rem;
    border-radius: 5px;
    box-shadow: 0px 0px 10px $text-color;
}

@media screen and (max-width:578px) {
    .form{
        width: 90%;
        font-size: 1rem;
    }
}
