@import '../../utils/variables.scss';

.container{
    max-width: 1400px;
    margin:60px auto;
    display: flex;
    color:$text-color;
    flex-direction: column;
    width:500px;
    box-shadow: 0px 0px 15px black;
    height:fit-content;
    font-family: 'Ubuntu', sans-serif;
    padding:50px 20px;
    border-radius: 10px;
    box-sizing: border-box;
    label{
        font-size: 1.2em;
        font-weight: 500;
        margin:10px 0px;
    }
    button{
        width:fit-content;
        margin:20px auto;
        padding:10px 20px;
        outline: none;
        border: none;
        border-radius: 20px;
        font-weight: 700;
        color:white;
        background-color: #248277;
        font-family: 'Ubuntu', sans-serif;
        font-size: 1.1em;
        cursor: pointer;
        transition-duration: 0.25s;
        &:hover{
            background-color: #036666;
        }
    }
    span{
        display: block;
        text-align: center;
        a{
            text-decoration: none;
            color:$text-color;
            margin:0px 5px;
            &:hover{
                color: #06d6a0;
            }
        }
    
    }
}
.Heading{
    text-align: center;
    font-size: 1.5em;
    font-weight: 900;
}

.row{
    width:100%;
    height:fit-content;
    display: grid;
    grid-template-columns: 10% 90%;
    margin-bottom: 50px;
    input{
        padding:10px;
        border-top-right-radius: 10px;
        height: 30px;
        border: none;
        background-color: #6c757d;
        border-bottom-right-radius:10px;
        font-size:19.2px;
        color:#f8f9fa;
        &:focus{
            outline: none;
            background-color: none;
        }
        &::placeholder{
            color:#f8f9fa;
            font-size: 19.2px;
        }
    }
}
.icon{
    height: 50px;
    margin-right:1px;
    background-color: #6c757d;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorMsg{
    margin-top: 0px;
    color:red;
}

@media screen and (max-width:578px){
    .container{
        width: 90%;
        
        padding:10px 10px;
        
    }
}