/*Colors*/
@import '../../utils/variables.scss';
$white-color:  #ffffff;


.limitedWidthBlockContainer {
    background-color: $white-color;
    color: $text-color;
    display: flex;
    justify-content: center;
}

.limitedWidthBlock {
    width: 100%;
    max-width: 1400px;
    padding: 0px 25px;
}






/*********/
/*Product*/
/*********/

/*Item Details*/
.item {
	margin: 100px 0;
	display: flex;
	justify-content: center;
}
.item article {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 50%;
    border: 2px solid $border-color;
    border-radius: 20px;
    box-shadow: 0px 0px 15px black;
}
.item__img {
	//width: 100%;
    background-color: $white-color;
    border-radius: 15px;
    padding: 50px;
    height: 300px;
}
.item__img img {
	width: 160px;
	height: 100%;
	object-fit: cover;
	object-position: center;
}
.item__content {
	width: 96%;
	color: $text-color;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
    text-align: center;
}
.item__content h1 {
	font-size: 48px;
	font-weight: 700;
	margin-top: 30px;
	margin-bottom: 0;
}
.item__content__titlePrice,
.item__content__description,
.item__content__settings {
	border-bottom: 1px solid $white-color;
}
.item__content__titlePrice p {
	margin: 0px;
}
.item__content__description {
	display: flex;
	flex-direction: column;
    margin-bottom:20px;
}
.item__content__description__title {
	font-weight: 600;
	font-size: 24px;
	margin-bottom: 0;
}
.item__content__settings {
	padding-top: 24px;
	padding-bottom: 18px;
}

.price{
	display: block;
	font-size: 1.5rem;
	font-weight: 700;
	margin: 8px auto;
}

.pricePromo{
	color: red;
	font-size: 1.5rem;
    font-weight: 700;
}

.prixAnnuler{
	display: block;
	font-size: 18px;
	font-weight: 600;
    text-decoration: line-through;
	margin: 8px auto;
}

.modifyBox{
	display: flex;
	justify-content: center;
	align-items: center;
}

.deleteContainer{
	button{
		height: 42px;
	}
}

.inactive{
	display: none;
}

.acitve{
	display: block;
}

.buttonGroup{
	display: flex;
	justify-content: center;
}


/***************/
/*Media queries*/
/***************/

@media (max-width: 991.98px) {
	.item article {
		width: 70%;
	}
	
}

@media (max-width: 578px) { 
    .item {
        margin: 60px 0;
    }
	.item article {
		width: 90%;
	}
    .item__content h1 {
        font-size: 24px;
    }
    h2{
        font-size: 16px;
    }
}