@import '../../utils/variables.scss';

@mixin hover($hover) {
    .page{
        a:#{$hover},a:focus{
            color:#fe3;
        }
    }
};

.logoFlotant{
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 999;
    top:50%;
    right:15px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px #ce134e;
    opacity: 0.5;
    transition: all 0.2s linear;
    @media (any-hover:hover){
        &:hover{
            cursor: pointer;
            opacity: 1;
            transform: scale(1.2);
        }
    }
    
    @media screen and (max-width:578px){
        &:active{
            cursor: pointer;
            opacity: 1;
            transform: scale(1.2);
        }
    }
    img{
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
    }
}