//COLORS
$red_color: #ff6060;

.notFound {
  color: $red_color;
  text-align: center;
  margin: 110px;

  h1 {
    font-size: 18rem;
    font-weight: 700;
  }
  p {
    font-size: 2.25rem;
    padding-bottom: 200px;
  }
  a {
    font-size: 1.125rem;
    color: $red_color;
    border-bottom: 1px solid $red_color;
  }
}

@media screen and (max-width: 900px) {
  .notFound {
    margin: 150px 50px 180px 50px;

    h1 {
      font-size: 6rem;
    }
    p {
      font-size: 1.125rem;
      padding-bottom: 100px;
    }
    a {
      font-size: 0.875rem;
    }
  }
}