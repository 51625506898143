@import '../../utils/variables.scss';

.star{
    width:15px;
    color:$color-secondaire;
    @media screen and (min-width:768px) {
       width:30px;
    }
 }

 .starGray{
    width:15px;
    color:gray;
    @media screen and (min-width:768px) {
       width:30px;
    }
 }
