/*Colors*/
@import '../../utils/variables.scss';
$main-Bg-color :white;
$main-text-color:  #3d4c68;



.limitedWidthBlockContainer {
    background-color: $main-Bg-color;
    color: $main-text-color;
    display: flex;
    justify-content: center;
}

.limitedWidthBlock {
    width: 100%;
    max-width: 1400px;
    padding: 0px 25px;
}

.title{
   margin: 5px;
   text-transform:Uppercase;
   font-size: 16px;
}

.cards {
    margin-bottom: 135px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
a{
    width: 310px;
        margin: 0 0 30px 0px;
        text-decoration: none;
    article{
    background-color: $main-Bg-color;
    border: 2px solid $border-color;
    display: flex;
    border-radius: 0.6rem;
    height: 250px;
    color: $main-text-color;

    img{
        width: 35%;
        height: 86%;
        padding-top: 1rem;
        padding-left: 1rem;
        object-fit: cover;
        object-position: center;
    }
    div{
        text-align: center;
        align-self: center;
        padding: 0 5px;
    }
    
}
}
    
}

.aoc{
    margin: 7px;
}

.prix{
    color: #fff;
    background-color: #02a78b;
    display: inline-flex;
    padding: 8px 32px;
    font-size: 1.2rem;
    line-height: 24px;
    border-radius: 80px;
    font-weight: bold;
}

.prxiAnnuler{
    margin-top: -10px;
    font-size: 1.2rem;
    text-decoration: line-through;
}

.prixOffre{
    margin: 12px 0px;
    color: #fff;
    background-color: $color-secondaire;
    display: inline-flex;
    padding: 8px 32px;
    font-size: 1.2rem;
    line-height: 24px;
    border-radius: 80px;
    font-weight: bold;
    position: relative;
    &::before{
        content:'Promo';
        overflow-wrap: normal;
        font-size: 0.7rem;
        font-weight: 400;
        position: absolute;
        color: gold;
        background-color: $color-primaire;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        right:140px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.plusDeDetail{
    color:$color-primaire;
    text-decoration: underline;
}

@media screen and (max-width:578px) {
    .limitedWidthBlock {
        text-align: center;
    }
    .cards {
        justify-content: space-around;
    }
}